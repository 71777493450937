* {
	box-sizing: border-box; }
.overflow_hide {
	overflow: hidden; }

@mixin flex_sc() {
	display: flex;
	justify-content: space-between;
	align-items: center; }
@mixin font($color,$fontSise,$lineHeight) {
	color: $color;
	font-size: $fontSise+px;
	line-height: $lineHeight+px;
	@include ff(); }
@mixin text_1() {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden; }
@mixin text_2() {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden; }
@mixin text_3() {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden; }
@mixin text_4() {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	overflow: hidden; }
@mixin ff() {
	font-family: "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
	font-weight: normal;
	font-style: normal; }
// 直播详情
.live_box {
	width: 100%;
	max-width: 1440px;
	min-width: 1150px;
	background-color: #fff;
	position: relative;
	margin: 0 auto;
	&::-webkit-scrollbar {
		display: none; }
	// 背景
	.box_bg {
		width: 100%;
		background-image: url('/public/img/watch_v2/bg_common.png');
		background-size: 100% 100%; }
	.live_header {
		height: 75px;
		width: 85%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 auto;
		.style_logo {
			img {
				width: 106px; }
			.live_logo {
				margin-left: 15px;
				width: 100px; } }
		.style_right {
			display: flex;
			align-items: center;
			>a,>div {
				margin-right: 40px;
				@include font(#FFFFFF,14,20);
				cursor: pointer; }
			.to_login {
				width: 30px;
				height: 30px;
				img {
					border-radius: 50%;
					width: 30px;
					height: 30px; } } } }
	.live_main_box {
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		max-width: 1200px;
		min-width: 908px;
		// <!-- 直播播放器 聊天室 -->
		.live_wrapper {
			width: 100%;
			display: flex;
			margin-top: 25px;
			align-items: center;
			justify-content: center;
			.live_video_wrapper {
				position: relative;
				margin: 0 auto;
				width: 908px;
				min-width: 908px;
				max-width: 908px;
				border-radius: 4px;
				background: #23232C;
				padding: 15px;
				height: 578px;
				min-height: 578px;
				max-height: 578px;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				.wrapper_top {
					width: 100%;
					height: 68px;
					padding: 0 12px 16px;
					padding-right: 26px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					// 主播
					.info_hoster {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						.hoster_avater {
							width: 36px;
							height: 36px;
							border-radius: 50%;
							img {
								width: 36px;
								height: 36px;
								border-radius: 50%; } }
						.hoster_name {
							@include font(#FFFFFF,16,20);
							margin-left: 10px; }
						.tmt_actor_pro {
							margin-left: 6px;
							width: 38px;
							height: 14px;
							background: url('/public/img/watch_v2/icon_pro.png');
							background-size: 100% 100%; }
						.tmt_actor_hongcertification {
							margin-left: 6px;
							width: 14px;
							height: 14px;
							background: url('/public/img/watch_v2/h5/icon_uc.png');
							background-size: 100% 100%; }
						.tmt_actor_lancertification {
							margin-left: 6px;
							width: 14px;
							height: 14px;
							background: url('/public/img/watch_v2/h5/icon_jg.png');
							background-size: 100% 100%; }
						.sub_btn_host {
							width: 70px;
							height: 28px;
							background: url('/public/img/watch_v2/icon_ygz.png');
							background-size: 100% 100%;
							margin-left: 20px;
							&.follow {
								width: 60px;
								height: 28px;
								background: url('/public/img/watch_v2/icon_gz.png');
								background-size: 100% 100%; } } }
					.info_living {
						display: flex;
						// 立即预约
						.liveSubscribe_btn {
							border-radius: 6px;
							padding: 8px 24px;
							display: flex;
							align-self: center;
							justify-content: center;
							margin-left: 40px;
							&.ordered {
								cursor: default;
								background: rgba(197, 0, 43, .2);
								@include font($tmt_color_red,14,20);
								&:after {
									content: '已预约'; } }
							&.to_order {
								cursor: pointer;
								background: $tmt_color_red;
								@include font(#FFFFFF,14,20);
								&:after {
									content: '立即预约'; } } }
						// 直播状态
						.living_status {
							display: flex;
							align-items: center;
							img {
								width: 14px;
								height: 14px;
								min-width: 14px;
								margin-right: 10px; }
							p {
								@include font(#FFFFFF,16,20); } } } }
				// 直播播放器
				.tmtLivePlayer {
					width: 880px !important;
					min-width: 880px !important;
					height: 495px !important;
					min-height: 495px !important;
					overflow: hidden;
					border-radius: 4px;
					position: relative; } }
			// 互动聊天室
			#tmtLiveChatroomWrap {
				width: 280px;
				min-width: 280px;
				max-width: 280px;
				height: 578px;
				min-height: 578px;
				max-width: 578px;
				border-radius: 4px;
				background: #23232C;
				overflow: hidden;
				position: relative;
				&::-webkit-scrollbar {
					display: none; }
				// 如果是直播结束并且可展示消息列表
				.tmt-chat-list {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					max-width: 100%;
					padding: 15px;
					overflow: scroll;
					&::-webkit-scrollbar {
						display: none; }
					li {
						position: relative;
						background: rgba(43,43,51,0.45);
						display: inline-block;
						*display: inline;
						*zoom: 1 {
							border-radius: 10px;
							margin-bottom: 4px;
							padding: 5px; }
						.tmt-msg-main {
							margin: 0;
							display: flex;
							align-items: center;
							justify-content: flex-start;
							flex-wrap: wrap;
							max-width: 100%;
							.tmt-user-info {

								span {
									display: inline-block;
									white-space: unset;
									text-overflow: unset;
									overflow: unset; }
								// 通知
								.tmt-user-live-notice {
									display: inline-block;
									width: 29px;
									min-width: 29px;
									height: 14px;
									background: url('/public/img/watch_v2/h5/icon_tz.png');
									background-size: 100% 100%;
									margin-right: 4px; }
								.tmt-user-name {
									max-width: unset;
									@include font(#FFE378,14,20);
									display: flex;
									align-items: center;
									.tmt-user-actor {
										max-width: unset;
										background: unset;
										padding: unset;
										border-radius: unset;
										position: relative;
										bottom: 0;
										display: flex;
										align-items: center; }
									// 身份信息
									.tmt_actor {
										.tmt_actor_live_host {
											width: 29px;
											min-width: 29px;
											height: 14px;
											background: url('/public/img/watch_v2/h5/icon_zb.png');
											background-size: 100% 100%;
											margin-right: 4px; }
										.tmt_actor_pro {
											width: 42px;
											min-width: 42px;
											height: 14px;
											background: url('/public/img/watch_v2/h5/icon_pro.png');
											background-size: 100% 100%;
											margin-right: 4px; }
										.tmt_actor_hongcertification {
											width: 14px;
											min-width: 14px;
											height: 14px;
											background: url('/public/img/watch_v2/h5/icon_uc.png');
											background-size: 100% 100%;
											margin-right: 4px; }
										.tmt_actor_lancertification {
											width: 14px;
											min-width: 14px;
											height: 14px;
											background: url('/public/img/watch_v2/h5/icon_jg.png');
											background-size: 100% 100%;
											margin-right: 4px; }
										.tmt_actor_level {
											width: 26px;
											min-width: 26px;
											height: 14px;
											margin-right: 4px; } } } }
							.tmt-msg-content {
								position: relative;
								display: inline-block;
								max-width: 100%;
								min-height: 35px;
								padding: 8px;
								font-size: 12px;
								border-radius: 0 5px 5px;
								vertical-align: middle;
								.tmt-msg-text {
									@include font(#fff,14,20); } } } } }
				.background_top_grey {
					width: 280px;
					position: absolute;
					top: 0;
					height: 100px;
					z-index: 1;
					border-radius: 4px;
					background: linear-gradient(rgb(29,29,32),rgba(0,0,0,0)); }
				.no_comment_box {
					width: 280px;
					position: absolute;
					bottom: 0;
					height: 100px;
					z-index: 99;
					background: rgba(0,0,0,.8);
					color: #fff;
					font-size: 14px;
					display: flex;
					align-items: center;
					justify-content: center; }
				// 修改第三方ui
				// 去掉聊天室导航标题
				.polyv-cr-head,
				// 加号功能
				.polyv-icon-more,
				// 加号功能面板
				.polyv-chat-input-more,
				// 时间
				.polyv-other-msg-time,
				// 头像
				.polyv-user-logo,
				// 送花等
				.polyv-msg {
					display: none !important; }
				// 公共
				.other-message {
					// 欢迎用户进入直播间
					.welcome-msg {
						text-align: center;
						min-height: 32px;
						background: url('/public/img/watch_v2/h5/bg_come_user.png');
						background-size: 100% 100%;
						border: unset;
						box-shadow: unset;
						color: #fff;
						font-size: 14px;
						font-weight: bold;
						width: 100%;
						&:after,
						&:before, {
							content: unset; }
						.welcome_nick {
							color: #fff;
							font-size: 14px;
							font-weight: bold;
							width: 60%;
							@include text_1();
							margin: 0 auto; } } }
				// 消息内容
				.polyv-msg-main {
					margin: 0;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					flex-wrap: wrap;
					max-width: 100%;
					padding: 8px;
					// 用户名
					.polyv-user-info {
						margin: 0;
						display: flex;
						span {
							white-space: unset;
							text-overflow: unset;
							overflow: unset; }
						.polyv-user-name {
							max-width: unset;
							@include font(#FFE378,14,20);
							margin-right: 5px;
							@include text_1(); }
						.polyv-msg-content {
							position: relative;
							display: inline-block;
							max-width: 100%;
							min-height: unset;
							padding: 0 8px;
							font-size: 14px;
							background-color: #fff;
							border-radius: unset;
							min-height: unset;
							padding: 0; }
						.polyv-user-actor {
							max-width: unset;
							background: unset;
							padding: unset;
							border-radius: unset;
							position: relative;
							bottom: -2px; }
						// 通知
						.polyv-user-live-notice {
							display: inline-block;
							width: 29px;
							min-width: 29px;
							height: 14px;
							background: url('/public/img/watch_v2/h5/icon_tz.png');
							background-size: 100% 100%;
							margin-right: 4px;
							margin-bottom: -2px; }
						// 身份信息
						.tmt_actor {
							span {
								display: inline-block; }
							.tmt_actor_live_host {
								width: 29px;
								min-width: 29px;
								height: 14px;
								background: url('/public/img/watch_v2/h5/icon_zb.png');
								background-size: 100% 100%;
								margin-right: 4px; }
							.tmt_actor_pro {
								width: 42px;
								min-width: 42px;
								height: 14px;
								background: url('/public/img/watch_v2/h5/icon_pro.png');
								background-size: 100% 100%;
								margin-right: 4px; }
							.tmt_actor_hongcertification {
								width: 14px;
								min-width: 14px;
								height: 14px;
								background: url('/public/img/watch_v2/h5/icon_uc.png');
								background-size: 100% 100%;
								margin-right: 4px; }
							.tmt_actor_lancertification {
								width: 14px;
								min-width: 14px;
								height: 14px;
								background: url('/public/img/watch_v2/h5/icon_jg.png');
								background-size: 100% 100%;
								margin-right: 4px; }
							.tmt_actor_level {
								width: 26px;
								min-width: 26px;
								height: 14px;
								margin-right: 4px; } } }
					// 消息文字
					.polyv-msg-text {
						@include font(#fff,14,20);
						word-break: break-all; } }
				// 有新消息
				.polyv-new-msg {
					bottom: 120px;
					padding: 4px 20px;
					background: #EBA42B;
					border-radius: 26px;
					color: #fff;
					font-size: 14px;
					width: auto;
					transform: translateX(-50%);
					left: 50%;
					justify-content: center;
					align-items: center;
					opacity: 1;
					transition: all .5s;
					line-height: 1.5;
					&:after {
						content: '';
						display: inline-block;
						width: 10px;
						height: 8px;
						background: url('/public/img/watch_v2/h5/icon_news_more.png');
						background-size: 100% 100%;
						margin-left: 12px; } }
				.polyv-self-msg {
					.polyv-msg-main {
						float: unset; } }
				// 聊天室输入框
				.polyv-chat-input {
					height: 98px;
					background: #23232C;
					padding: 8px;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					// 快捷评论
					.live_short_comment_ul {
						display: flex;
						width: 100%;
						overflow: auto;
						position: absolute;
						padding-right: 15px;
						z-index: 2;
						top: 20px;
						&::-webkit-scrollbar {
							display: none; }
						li {
							@include font(#333,12,20);
							padding: 2px 14px;
							margin-right: 12px;
							background: rgba(255,255,255,0.8);
							border-radius: 15px;
							word-break: keep-all;
							cursor: pointer;
							white-space: nowrap; } }
					// 输入框
					.show-1-icon {
						>div {
							padding: 0;
							display: flex;
							justify-content: flex-start;
							align-items: center;
							width: 100%;
							position: absolute;
							bottom: 20px;
							height: unset;
							padding-right: 20px;
							.polyv-chat-input-content {
								width: 66%;
								min-width: 66%; }
							// 图标
							.polyv-icon {
								position: unset; }
							input {
								border-radius: 18px;
								padding: 8px 20px; }
							// 发送
							.polyv-mobile-send {
								display: inline-block;
								color: #FFE378;
								font-size: 14px;
								position: unset;
								background: url('/public/img/watch_v2/h5/icon_send.png');
								background-size: 100% 100%;
								width: 38px;
								height: 38px;
								min-width: 38px; }
							.polyv-icon-box {
								display: flex;
								align-items: center;
								justify-content: space-between;
								margin-left: 10px;
								width: 100%;
								// 表情
								.polyv-icon-emotion {
									background: url('/public/img/watch_v2/h5/icon_emo.png');
									background-size: 100% 100%;
									width: 38px;
									height: 38px;
									min-width: 38px; }
								.polyv-icon-flower {
									background: url('/public/img/watch_v2/h5/icon_flower.png');
									background-size: 100% 100%;
									width: 38px;
									height: 38px; } } } }
					>.polyv-chat-input-main {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: flex-start;
						width: 100%;
						background: #333333;
						height: 36px;
						padding: 10px;
						max-width: 100%;
						.polyv-chat-input-middle {
							width: 55%; }
						.polyv-input-ele {
							height: 36px;
							background: #333333;
							display: flex;
							align-items: center;
							text-align: left;
							line-height: 36px;
							color: #fff;
							overflow: auto;
							border: unset;
							&::-webkit-input-placeholder {
								color: #fff; } }
						.polyv-pc-send {
							width: unset;
							color: #FFE378;
							background: unset;
							height: unset;
							font-size: 14px;
							word-break: keep-all; }
						.polyv-chat-input-top {
							padding: 0;
							padding-left: 10px;
							margin-left: 10px;
							border-left: 1px solid #666;
							.polyv-fl {
								display: flex;
								.polyv-icon-emotion {
									background: url('/public/img/watch_v2/icon_bq.png');
									background-size: 100% 100%; }
								.polyv-icon-flower {
									background: url('/public/img/watch_v2/icon_xh.png');
									background-size: 100% 100%; } } } } }
				// 未登录的聊天输入框
				.to-tmt-login {
					.polyv-chat-input-main {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: flex-start;
						width: 100%;
						background: #333333;
						height: 36px;
						padding: 10px;
						span {
							display: inline-block;
							position: unset; }
						// 未登录
						.polyv-icon-no-login {
							@include font(#fff,14,20);
							background: #333333;
							border-radius: 18px;
							margin-right: 10px;
							flex: 1;
							.hong_txt {
								color: $tmt_color_red; } }
						.polyv-chat-input-top {
							padding: 0;
							padding-left: 10px;
							margin-left: 10px;
							border-left: 1px solid #666;
							display: flex;
							.polyv-icon-emotion {
								background: url('/public/img/watch_v2/icon_bq.png');
								background-size: 100% 100%; }
							.polyv-icon-flower {
								background: url('/public/img/watch_v2/icon_xh.png');
								background-size: 100% 100%; } } } }
				// 聊天列表
				.polyv-chat-room {
					padding-top: 0;
					// 直播背景
					>.polyv-cr-body {
						background: #23232C;
						padding: 0; }
					.other-message {
						// 用户信息
						.user-info {
							font-size: 12px;
							line-height: 12px; } }
					#tab-chat {
						padding-bottom: 92px; }
					// 消息列表
					.tab-chat-content {
						padding: 15px;
						.polyv-chat-list {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							max-width: 100%;
							>li {
								background: rgba(43, 43, 51, 0.45);
								display: inline-block;
								// *display: inline
								zoom: 1;
								border-radius: 10px;
								margin-bottom: 4px;
								.polyv-msg-content {
									min-height: unset;
									background: rgba(43, 43, 51, 0.45);
									padding: 0;
									padding-right: 8px; } }
							.polyv-other-msg-login {
								.polyv-msg-content {
									background: rgba(43, 43, 51, 0.45);
									display: inline-block;
									// *display: inline
									zoom: 1;
									border-radius: 10px;
									padding: 5px 10px;
									min-height: unset;
									.polyv-msg-text {
										word-break: break-all;
										@include font(#FFE378,14,20); } } } } } } } }

		// 直播信息 分享观看点赞
		.live_info {
			padding-top: 36px;
			width: 100%;
			margin: 0 auto;
			display: flex;
			padding-bottom: 40px;
			align-items: center;
			justify-content: space-between;
			.countdown {
				font-size: 18px;
				padding-left: 70px;
				color: #fff;
				mark {
					color: $tmt_color_red;
					background: transparent; } }
			>div,.info_likes {
				display: flex;
				align-items: center;
				justify-content: flex-end; }
			.share {
				display: flex;
				align-items: center;
				>a,i {
					display: inline-block;
					margin-left: 10px;
					width: 26px;
					height: 26px;
					min-width: 26px; }
				.share_wb_btn {
					background: url(/public/img/watch_v2/icon_wb.png);
					background-size: 100% 100%;
					&:hover {
						background: url(/public/img/watch_v2/icon_wb_hover.png);
						background-size: 100% 100%; } }
				.share_wx_btn {
					background: url(/public/img/watch_v2/icon_wx.png);
					background-size: 100% 100%;
					position: relative;
					bottom: -2px;
					left: -5px;
					&:hover {
						background: url(/public/img/watch_v2/icon_wx_hover.png);
						background-size: 100% 100%; } }
				.share_qz_btn {
					background: url(/public/img/watch_v2/icon_qz.png);
					background-size: 100% 100%;
					&:hover {
						background: url(/public/img/watch_v2/icon_qz_hover.png);
						background-size: 100% 100%; } }
				img {
					width: 26px;
					height: 26px;
					min-width: 26px; }
				>p {
					font-size: 18px;
					color: #fff; }
				.dropdown-menu-part {
					margin-left: 10px; }
				.dropdown-menu,.wx-dropdown {
					width: 130px;
					left: -54px;
					bottom: 36px; } }
			.info_watch,.info_goods,.info_share {
				color: #FFFFFF;
				font-size: 15px;
				margin-left: 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				cursor: pointer;
				img {
					margin-right: 3px;
					width: 13px;
					min-width: 13px; } }
			.info_goods {
				.info_goods_icon {
					width: 13px;
					display: inline-block;
					height: 12px;
					margin-right: 3px;
					background: url(/public/img/watch_v2/logo_good.png);
					background-size: 100% 100%; }
				&:hover,&.current {
					color: $tmt_color_red;
					.info_goods_icon {
						background: url(/public/img/watch/logo_good.png);
						background-size: 100% 100%; } } }
			.info_watch {
				img {
					width: 19px; } }
			.info_right {
				.share {
					margin-right: 40px; } }
			.info_left {
				p {
					color: #FFFFFF;
					display: flex;
					align-items: center;
					font-size: 20px;
					span {
						font-size: 22px; } }
				.to_paid {
					color: #fff;
					background: $tmt_color_red;
					padding: 4px 10px;
					font-size: 14px;
					border-radius: 8px;
					margin-left: 15px; }
				.pro_grice {
					color: #FFFFFF;
					font-size: 18px;
					margin-left: 54px; }
				.get_pro_btn {
					color: #DEB966;
					font-size: 18px;
					margin-left: 14px;
					text-decoration: underline; } } } }
	// 直播详情介绍
	.live_des {
		margin: 60px auto;
		min-width: 1120px;
		width: 1120px;
		>h2 {
			font-weight: bold;
			@include font(#333,30,40); }
		.time_part {
			@include font(#262626,16,18);
			padding: 20px 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			display: inline-block;
			img {
				margin-right: 10px;
				width: 18px;
				margin-bottom: 2px; } }
		.content_part {
			border-top: 2px solid #F5F5F5;
			padding-top: 30px;
			padding-bottom: 40px;
			margin-top: 10px;
			p,a,blockquote {
				@include font(#333,16,28);
				margin-bottom: 20px; }
			a {
				color: $tmt_color_red; }
			p.caption {
				@include font(#999,16,28);
				text-align: center;
				margin: 30px auto;
				max-width: 720px; }
			ul,ol {
				color: #333333;
				li {
					@include font(#333,16,28);
					margin-bottom: 20px; } }
			h2 {
				@include font(#333,24,28);
				margin-bottom: 30px; }
			h3,h4 {
				@include font(#333,22,28);
				margin-bottom: 30px; }
			img {
				max-width: 720px;
				margin: 40px 0 40px;
				display: block; } }
		// 了解更多
		.more_part {
			padding-top: 40px;
			>h3 {
				a {
					display: block;
					color: #373737;
					font-size: 20px;
					line-height: 40px;
					font-weight: bold;
					margin-bottom: 15px; } }
			.fonts {
				list-style: disc;
				a {
					color: #666;
					font-size: 18px;
					line-height: 40px;
					margin-bottom: 10px;
					display: block; } }
			.posts_ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				>li {
					width: 272px;
					background: #FAFBFF;
					border-radius: 5px;
					overflow: hidden;
					margin-bottom: 20px;
					position: relative;
					height: 260px;
					.url_box {
						width: 48px;
						height: 25px;
						position: absolute;
						top: 0;
						left: 0;
						z-index: 3; }
					a {
						display: inline-block;
						overflow: hidden; }
					.imgs_box {
						height: 153px; }
					.imgs {
						width: 100%;
						height: 140px; }
					.tits {
						color: #000;
						font-size: 18px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						@include ff();
						margin: 10px 0px;
						line-height: 1.5;
						min-height: 54px; }
					.info {
						margin: 0 18px;
						.label {
							display: flex;
							flex-wrap: wrap;
							max-height: 21px;
							min-height: 21px;
							overflow: hidden;
							width: 100%;
							// justify-content: space-between
							>a {
								color: $tmt_color_red;
								font-size: 14px;
								padding: 0px 6px;
								background: #FFF1F3;
								border-radius: 5px;
								// margin-bottom: 5px
								margin-right: 5px; } }
						.author {
							color: $tmt_color_red;
							font-size: 14px;
							margin-top: 10px;
							margin-bottom: 10px;
							min-height: 21px; }
						.other {
							margin-bottom: 10px;
							display: flex;
							justify-content: space-between;
							align-items: center;
							.get_like {
								display: flex;
								justify-content: space-between;
								align-items: center;
								>div {
									margin: 0 5px; }
								span {
									color: #808080;
									font-size: 12px;
									margin-left: 4px; } }
							p {
								color: #808080;
								font-size: 12px; }
							.get_shoucan {
								cursor: pointer;
								margin-left: 10px;
								margin-right: 0px;
								@include flex_sc();
								.tmt_bookmarks {
									width: 24px;
									height: 12px;
									margin-right: 2px;
									background: url(/public/img/common/icon_txt_sc.png);
									background-size: 100% 100%; }
								span {
									@include font(#808080,12,12); }
								// &:hover
								// 	.tmt_bookmarks
								// 		background: url(/public/img/common/icon_txt_sc_active.png)
								// 		background-size: 100% 100%
								// 	span
								// 		@include font($tmt_color_red,12,12)
								&.current {
									span {
										@include font($tmt_color_red,12,12); }
									.tmt_bookmarks {
										background: url(/public/img/common/icon_txt_sc_active.png);
										background-size: 100% 100%; } } }
							.get_pl {
								cursor: pointer;
								margin-right: 10px;
								@include font(#808080,12,12);
								@include flex_sc();
								.tmt_pl {
									width: 12px;
									height: 10px;
									margin-right: 2px;
									background: url(/public/img/posts_v2/icon_pl.png);
									background-size: 100% 100%;
									&:hover {
										background: url(/public/img/common/icon_pl_active.png);
										background-size: 100% 100%; } }
								span {
									display: inline-block;
									@include font(#808080,12,12); } }
							.get_goods {
								cursor: pointer;
								margin-left: 10px;
								@include flex_sc();
								.tmt_goods {
									width: 12px;
									height: 10px;
									margin-right: 2px;
									background: url(/public/img/index_v2/icon_goods.png);
									background-size: 100% 100%;
									// &:hover
									// 	background: url(/public/img/index_v2/icon_goods4.png)
 }									// 	background-size: 100% 100%
								span {
									@include font(#808080,12,12); }
								&.current {
									span {
										@include font($tmt_color_red,12,12); }
									.tmt_goods {
										background: url(/public/img/index_v2/icon_goods4.png);
										background-size: 100% 100%; } } }
							.get_watch {
								display: flex;
								align-items: center;
								img {
									width: 14px;
									height: 10px; } }
							.get_posts {
								img {
									width: 11px; } } } } }
				.part_video {
					&:after {
						content: '';
						display: inline-block;
						position: absolute;
						top: 100px;
						left: 22px;
						width: 25px;
						height: 25px;
						background: url(/public/img/index_v2/type_video.png);
						background-size: 100% 100%; } }
				.part_atlas {
					&:after {
						content: '';
						display: inline-block;
						position: absolute;
						top: 100px;
						left: 22px;
						width: 24px;
						height: 20px;
						background: url(/public/img/index_v2/type_atlas.png);
						background-size: 100% 100%; } }
				.part_audio {
					&:after {
						content: '';
						display: inline-block;
						position: absolute;
						top: 100px;
						left: 22px;
						width: 23px;
						height: 20px;
						background: url(/public/img/index_v2/type_audio.png);
						background-size: 100% 100%; } }
				.part_watch,.part_watch_personal {
					&:after {
						content: '';
						display: inline-block;
						position: absolute;
						top: 100px;
						left: 22px;
						width: 23px;
						height: 20px;
						background: url(/public/img/index_v2/type_live.png);
						background-size: 100% 100%; } }
				.part_world {
					&:after {
						content: '快报';
						display: inline-block;
						position: absolute;
						top: 0;
						left: 0;
						padding: 5px 10px;
						line-height: 1;
						background: rgba(197, 0, 43, 0.5);
						color: #fff;
						font-size: 14px;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 4px 0 4px 0; } } }
			.list_more_btn {
				color: #fff;
				background: $tmt_color_red;
				padding: 4px 10px;
				font-size: 14px;
				margin: 0 auto;
				border-radius: 4px;
				margin-top: 40px;
				display: inline-block; } } }
	.live_footer {
		background-color: #0A162A;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 48px 0 15px;
		>div {
			margin-bottom: 23px; }
		a {
			color: #666666;
			font-size: 16px;
			margin: 0 40px; }
		img {
			width: 23px; } } }
.tips_mode {
	width: 100%;
	max-width: 1440px;
	min-width: 1150px;
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 20;
	margin: 0 auto;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.9);
	z-index: 1000;
	.scroll {
		overflow-y: scroll;
		padding: 20vh 140px 20vh;
		height: 100vh;
		position: relative;
		width: 80%;
		margin: 0 auto; }
	.content {
		width: 100%;
		position: relative;
		h3 {
			color: $tmt_color_red;
			font-size: 26px;
			line-height: 1.5;
			margin-bottom: 20px; }
		>div {
			margin-bottom: 20px;
			p {
				font-size: 20px;
				line-height: 1.5; }
			.Q {
				color: $tmt_color_red; }
			.A {
				color: #fff; } } }
	.close_btn {
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-150%, 200%);
		width: 30px; }
	.tmt_er {
		margin: 70px auto 0;
		display: block;
		width: 200px; } }

.wx_share_box {
	width: 100%;
	height: 100%;
	background: rgba(51, 51, 51, 0.8);
	z-index: 15;
	position: fixed;
	top: 0;
	left: 0;
	.pop_re {
		display: inline-block;
		top: 50%;
		left: 50%;
		margin: -140px 0 0 -140px;
		position: absolute;
		width: 280px;
		height: 280px;
		img {
			width: 100%;
			height: 100%; } }
	.close_code {
		z-index: 15;
		right: -20%;
		position: absolute;
		color: #fff;
		top: -20%;
		width: 50px;
		height: 50px; } }

.watch_list_box {
	margin-top: 115px;
	width: 100%;
	.list_banner {
		height: 527px;
		overflow: hidden;
		min-width: 1440px;
		max-width: 1440px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		background: url(/public/img/live_v3/web/bg_live.png);
		background-size: 100% 100%;
		.banner_l {
			width: 789px;
			height: 443px;
			margin-right: 37px;
			.ul_focus {
				position: relative;
				width: 789px;
				height: 443px;
				li {
					position: absolute;
					left: 0;
					bottom: 0;
					height: 100%;
					border-radius: 5px;
					overflow: hidden;
					a {
						display: block;
						width: 100%;
						height: 100%; }
					img {
						width: 100%;
						height: 100%; }
					.tit {
						@include font(#FFFFFF,16,24);
						@include text_2();
						position: absolute;
						bottom: 20px;
						left: 25px;
						width: 734px; }
					.alpha {
						background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
						// opacity: 0.88
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 90px; }
					// 直播中
					.live_status {
						position: absolute;
						left: 0;
						top: 0;
						display: flex;
						align-items: center;
						background: rgba(0,0,0,.67);
						padding: 4px 20px;
						border-radius: 4px 0px 4px 0px;
						@include font(#fff,14,20);
						&.coming_soon {
							background: #FFB900; }
						&.live_teaser {
							background: $tmt_color_red; }
						&.living {
							background: #F95355;
							&:before {
								content: '';
								display: inline-block;
								width: 10px;
								height: 10px;
								min-width: 10px;
								margin-right: 5px;
								background: url(/public/img/watch_v2/icon_living_dong.png);
								background-size: 100% 100%; } }
						&.live_replay {
							background: #017AFF; }
						&.end {
							background: rgba(0,0,0,.67); } } }
				.active {
					z-index: 10; } } }
		.banner_r {
			.ul_tab {
				width: 362px;
				height: 443px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				li {
					width: 100%;
					height: 77px;
					border: 2px solid transparent;
					position: relative;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					padding: 15px 30px;
					border-radius: 5px;
					// overflow: hidden
					a {
						display: inline-block;
						border-radius: 5px;
						overflow: hidden; }
					img {
						width: 100%;
						height: 100%;
						position: absolute;
						bottom: 0;
						left: 0;
						border-radius: 5px; }
					.alpha {
						width: 100%;
						height: 100%;
						background: rgba(0,0,0,.8);
						position: absolute;
						bottom: 0;
						left: 0;
						border-radius: 5px; }
					.tit {
						@include font(#FFFFFF,16,24);
						@include text_2();
						position: relative;
						z-index: 2; } }
				.active {
					border: 2px solid $tmt_color_red;
					.alpha {
						background: rgba(85,9,26,.96); }
					&:after {
						content: '';
						background: url(/public/img/live_v3/web/icon_hover.png);
						background-size: 100% 100%;
						display: inline-block;
						width: 11px;
						height: 17px;
						position: absolute;
						top: 50%;
						left: 0;
						transform: translate(-100%,-50%); }
					.tit {
						color: #FFFFFF; } } } } }
	// 直播的导航
	.list_nav {
		width: 100%;
		overflow-x: auto;
		position: relative;
		-webkit-overflow-scrolling: touch;
		max-width: 1190px;
		margin: 0 auto;
		border-bottom: 1px solid #E9E9E9;
		.type_container {
			width: 95%;
			margin: 0;
			.swiper-slide {
				width: 50px;
				width: auto;
				padding-right: 30px;
				&:last-child {
					padding-right: 0; } } }
		.button_next,.button_pre {
			top: 55%;
			&.swiper-button-disabled {
				opacity: 0.5; } }
		&::-webkit-scrollbar {
			display: none; }
		ul {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			min-width: 1190px;
			max-width: 1190px;
			margin: 0 auto;
			white-space: nowrap;
			li {
				color: #333333;
				font-size: 22px;
				padding: 20px 30px;
				letter-spacing: 1px;
				font-family: PingFangSC-Regular;
				cursor: pointer;
				a {
					color: #333333; }
				&.current {
					color: #333333;
					font-size: 24px;
					position: relative;
					&:after {
						content: '';
						display: inline-block;
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 3px;
						background: #333333; } } } } }
	.list_item {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		min-width: 1190px;
		max-width: 1190px;
		margin: 30px auto;
		.ul {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			min-width: 1190px;
			max-width: 1190px;
			flex-wrap: wrap;
			.part_live {
				margin-bottom: 30px;
				width: 384px;
				display: flex;
				flex-direction: column;
				box-shadow: 0px 2px 5px 0px #F4F4F4;
				border-radius: 6px;
				overflow: hidden;
				border: 1px solid #F4F4F4;
				.live_top {
					width: 100%;
					height: 216px;
					position: relative;
					.live_play_href {
						display: inline-block;
						width: 100%;
						height: 100%;
						overflow: hidden;
						.live_img {
							width: 100%;
							height: 100%; } } }
				.live_info {
					width: 100%;
					padding: 15px;
					.info_tit {
						@include font(#222,16,24);
						@include text_2();
						min-height: 48px; }
					.info_bottom {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-top: 10px;
						// 时间
						.info_time {
							@include font(#999,12,24);
							display: flex;
							justify-content: flex-start;
							align-items: center;
							&:before {
								content: '';
								display: inline-block;
								width: 11px;
								height: 11px;
								margin-right: 5px;
								background: url('/public/img/watch_v2/icon_time.png');
								background-size: 100% 100%; } }
						// 立即预约
						.liveSubscribe_btn {
							border-radius: 15px;
							padding: 5px 12px;
							display: flex;
							align-self: center;
							justify-content: center;
							&.ordered {
								cursor: default;
								background: rgba(197, 0, 43, .12);
								@include font($tmt_color_red,12,16);
								&:after {
									content: '已预约'; } }
							&.to_order {
								cursor: pointer;
								background: $tmt_color_red;
								@include font(#FFFFFF,12,16);
								&:after {
									content: '立即预约'; } } } }
					// 主播
					.info_hoster {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						margin-top: 15px;
						.hoster_avater {
							width: 20px;
							height: 20px;
							border-radius: 50%;
							img {
								width: 20px;
								height: 20px;
								border-radius: 50%; } }
						.hoster_name {
							@include font(#666,14,20);
							margin-left: 8px; }
						.tmt_actor_pro {
							margin-left: 6px;
							width: 38px;
							height: 14px;
							background: url('/public/img/watch_v2/icon_pro.png');
							background-size: 100% 100%; }
						.tmt_actor_hongcertification {
							margin-left: 6px;
							width: 14px;
							height: 14px;
							background: url('/public/img/watch_v2/h5/icon_uc.png');
							background-size: 100% 100%; }
						.tmt_actor_lancertification {
							margin-left: 6px;
							width: 14px;
							height: 14px;
							background: url('/public/img/watch_v2/h5/icon_jg.png');
							background-size: 100% 100%; } } } }

			// 直播中
			.live_status {
				position: absolute;
				left: 0;
				top: 0;
				display: flex;
				align-items: center;
				.status_txt {
					background: rgba(0,0,0,.67);
					padding: 4px 20px;
					border-radius: 4px 0px 0px 0px;
					@include font(#fff,14,20); }
				.live_views {
					background: rgba(0,0,0,.67);
					padding: 4px 15px;
					border-radius: 0px 0px 4px 0px;
					@include font(#fff,14,20); } }
			.coming_soon {
				.status_txt {
					background: #FFB900; } }
			.live_teaser {
				.status_txt {
					background: $tmt_color_red; } }
			.living {
				.status_txt {
					background: #F95355;
					&:before {
						content: '';
						display: inline-block;
						width: 10px;
						height: 10px;
						min-width: 10px;
						margin-right: 5px;
						background: url(/public/img/watch_v2/icon_living_dong.png);
						background-size: 100% 100%; } } }
			.live_replay {
				.status_txt {
					background: #017AFF; } }
			.end {
				.status_txt {
					background: rgba(0,0,0,.67); } } }
		.hide {
			display: none; } } }

// 加载更多按钮
#load_more,.load_more {
	font-size: 14px;
	color: #ccc;
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	&.load_over {
		.loading {
			display: none; } }
	.loading {
		display: inline-block;
		height: 20px;
		width: 20px;
		border-radius: 100%;
		margin: 10px;
		border: 2px solid #ccc;
		border-bottom-color: transparent;
		vertical-align: middle;
		-webkit-animation: rotate 1.1s infinite linear;
		animation: rotate 1.1s infinite linear; }
	// 加载更多按钮
	.get_more_btn {
		cursor: pointer;
		width: 250px;
		height: 46px;
		@include font(#c5002b, 16, 24);
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 80px auto 0;
		border: 1px solid #c5002b;
		border-radius: 4px;
		transition: 0.5s background-color;

		&:hover,
		&:active {
		   background-color: rgba(197, 0, 43, 0.2); }

		.loading {
			border-color: #c5002b;
			border-bottom-color: transparent; } } }

// 加载效果旋转
@-webkit-keyframes rotate {
	0% {}
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	100% {}
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg); }
@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg); }
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg); } }
.jw-controlbar.jw-background-color.jw-reset {
	width: 100%; }
// 浮动表单按钮
.fixed_box {
	z-index: 999;
	width: 80px;
	height: 80px;
	position: fixed;
	right: 30px;
	bottom: 30px;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-image: url("/public/img/watch/icon_form-bai.png");
	a {
		display: block;
		width: 100%;
		height: 100%; }
	&:hover {
		background-image: url("/public/img/watch/icon_form-cai.png"); } }
// 弹窗
.tmt_layer_tips {
	min-width: 480px!important;
	min-height: 240px!important;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	.layui-layer-title {
		width: 100%;
		background: #fff;
		margin: 0 30px;
		text-align: center;
		padding: 0 30px; }
	.layui-layer-content {
		width: 100%;
		@include font(#666666,14,22);
		text-align: center;
		padding: 0 30px; }
	.layui-layer-btn {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 30px 30px;
		.layui-layer-btn0 {
			background: $tmt_color_red;
			border-radius: 20px;
			@include font(#fff,14,40);
			height: 40px;
			line-height: 40px;
			padding: 0 30px;
			border-color: $tmt_color_red; }
		.layui-layer-btn1 {
			margin-left: 48px;
			padding: 0 30px;
			background: #fff;
			border-radius: 20px;
			@include font(#666,14,40);
			border: 1px solid #D4D4D4;
			height: 40px; } } }
.tmt_tips_red {
	background: #FFEFE6 !important;
	border-radius: 5px !important;
	overflow: hidden;
	.layui-layer-title {
		display: none; }
	.layui-layer-content {
		background: #FFEFE6;
		border-radius: 5px;
		border: 1px solid #FFD3BB;
		padding: 13px 20px;
		@include font(#FF4C4C,14,22);
		font-size: 14px;
		display: flex;
		align-items: center;
		&:before {
			content: '';
			display: inline-block;
			width: 15px;
			height: 15px;
			min-width: 15px;
			margin-right: 10px;
			background: url(/public/img/common/tips_red.png);
			background-size: 100% 100%; } } }
.tmt_tips_green {
	background: #BBF2D0 !important;
	border-radius: 5px !important;
	overflow: hidden;
	.layui-layer-title {
		display: none; }
	.layui-layer-content {
		background: #BBF2D0;
		border-radius: 5px;
		border: 1px solid #BBF2D0;
		padding: 13px 20px;
		@include font(#19BF6C,14,22);
		font-size: 14px;
		display: flex;
		align-items: center;
		&:before {
			content: '';
			display: inline-block;
			width: 15px;
			height: 15px;
			min-width: 15px;
			margin-right: 10px;
			background: url(/public/img/common/tips_green.png);
			background-size: 100% 100%; } } }
.tmt_tips_yellow {
	background: #FFE89E !important;
	border-radius: 5px !important;
	overflow: hidden;
	.layui-layer-title {
		display: none; }
	.layui-layer-content {
		background: #FFE89E;
		border-radius: 5px;
		border: 1px solid #FFE89E;
		padding: 13px 20px;
		@include font(#19BF6C,14,22);
		font-size: 14px;
		display: flex;
		align-items: center;
		&:before {
			content: '';
			display: inline-block;
			width: 15px;
			height: 15px;
			min-width: 15px;
			margin-right: 10px;
			background: url(/public/img/common/tips_yellow.png);
			background-size: 100% 100%; } } }
// 直播预约弹窗
.tmt_tips_live_subscribe {
	border-radius: 10px !important;
	.live_s_mode {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 70px 90px;
		.s_tit {
			color: $tmt_color_red;
			font-size: 32px;
			margin-bottom: 20px;
			line-height: 1; }
		.s_des {
			color: #333;
			margin-bottom: 20px;
			font-size: 20px;
			line-height: 1; }
		.s_img {
			width: 160px;
			height: 160px; } } }
